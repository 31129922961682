import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import FAQ from './components/FAQ';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/faq" element={<FAQ/>} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import '../styles/tailwind.css';
import fb from '../kepek/facebook.png';
import insta from '../kepek/instagram.png';

const Home = () => {
  return (
    <div id="home-container" className="container background-image flex flex-col justify-center items-center p-4">
      <h1 id="title" className="text-3xl sm:text-5xl font-bold mb-4 sm:mb-8 text-white text-center">Tatabánya Mustangs</h1>
      <div id="logo-container" className="flex items-center mb-8">
        <a
          href="https://www.facebook.com/Tatabanya.Mustangs"
          target="_blank"
          rel="noopener noreferrer"
          className="logo-item facebook-item rounded-full p-4 bg-blue-500 hover:bg-blue-600"
          style={{ marginRight: '40px' }}
        >
          <img
            src={fb}
            alt="Facebook Logo"
            className="facebook-icon"
            style={{ width: '110px', height: '110px' }}
          />
        </a>
        <a
          href="https://instagram.com/tatabanyamustangs?igshid=MzNlNGNkZWQ4Mg=="
          target="_blank"
          rel="noopener noreferrer"
          className="logo-item instagram-item rounded-full p-4 bg-purple-500 hover:bg-purple-600"
          style={{ marginLeft: '40px' }}
        >
          <img
            src={insta}
            alt="Instagram Logo"
            className="instagram-icon"
            style={{ width: '110px', height: '110px' }}
          />
        </a>
      </div>

      <p id="description" className="text-lg sm:text-xl mb-6 sm:mb-12 leading-relaxed text-white text-center">
        Edzéseink: Péntek 19:50 Vasárnap 13:50 Tatabánya, Olimpikon út.
      </p>
      <div id="button-container" className="button-container flex justify-center mt-8">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdYK8Gz10GPZ-C1HIIBnCyXwFWbDhVUBgH2TqzScOXUjODsgw/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="button bg-blue-500 hover:bg-blue-600 text-white py-3 px-8 rounded-lg text-lg sm:text-xl font-semibold shadow-md"
        >
          CSATLAKOZZ!
        </a>
      </div>
      <a
        href="/faq"
        id="faq-link"
        className="bg-blue-500 hover:bg-blue-600 text-white py-3 px-8 rounded-lg text-lg sm:text-xl font-semibold shadow-md mt-4"
      >
        Kérdésed van?
      </a>
    </div>
  );
};

export default Home;

import React from 'react';
import '../styles/tailwind.css';


const FAQ = () => {
  return (
    <div id="faq-container" className="container background-image bg-gray-200 flex flex-col justify-start items-center p-4 min-h-screen">
      <div id="faq-content" className="text-blue-900 max-w-3xl mx-auto overflow-y-scroll" style={{ scrollBehavior: 'smooth' }}>
      <p className="mb-4 question">
          Hogyan tudok csatlakozni a csapathoz?
        </p>
        <p className="mb-4 answer">
          A legegyszerűbb módja, hogy egyszerűen lejössz egy edzésre! Ha előtte esetleg kérdésed lenne, írj nekünk Facebookon vagy Instagramon, és igyekszünk mihamarabb válaszolni rá.
        </p>
        <p className="mb-4 question">
          Mikor és hol vannak az edzések?
        </p>
        <p className="mb-4 answer">
          2008.12.31 és 2006.01.01 között születetteknek:<br/>
          KEDD 16:00-18:00 - Jubi park<br/>
          VASÁRNAP 14:00-16:30 - Olimpikon úti sporttelep<br/>
          Mindenki más:<br/>
          PÉNTEK 19:50-22:00 – Olimpikon úti sporttelep<br/>
          VASÁRNAP 14:00-16:30 - Olimpikon úti sporttelep
        </p>
        <p className="mb-4 question">
          Mi az a tryout?
        </p>
        <p className="mb-4 answer">
          A tryout egy próbaedzés, aminek a célja, hogy kipróbáld a sportot. Van lehetőséged különböző mozgásformákon belül megnézni, milyen is a sportág belülről, hogy aztán eldönthesd, szeretnél-e a további edzéseken résztvenni.
        </p>
        <p className="mb-4 question">
        Milyen felszerelésre van szükségem a tryoutra vagy az első edzésekre?
        </p>
        <p className="mb-4 answer">
        Az első edzések során az időjárásnak megfelelő ruhában érkezz, amit nem sajnálsz és tudsz benne
        szabadon mozogni. Ha van műfüves stoplisod, hozd el és mozogj abban, ha nincs, semmi gond. Elsőre
        semmi másra nem lesz szükséged.
        </p>
        <p className="mb-4 question">
        Hány éves kortól vannak edzések?
        </p>
        <p className="mb-4 answer">
        Jelenlegi edzéseink keretében 2008.12.31 előtt született játékosok felkészülését tudjuk vállalni, de
        folyamatosan bővül az utánpótlás csapatunk. Amennyiben szeretnéd kipróbálni magad, de még nem
        vagy 15, írj ránk facebookon és megtaláljuk a módját, hogy belekezdj az edzésekbe.
        </p>
        <p className="mb-4 question">
        Mennyivel a kezdés után van szükségem sisakra és vállvédőre?
        </p>
        <p className="mb-4 answer">
        Az első három-négy hétben semmiképpen nem lesz szükséged a vállvédőre és a sisakra, a felkészülés
        itt még ezek nélkül zajlik. A kontaktot is tanulni kell, a fokozatosságra pedig különösen figyelünk
        kezdő játékosok esetében.
        </p>
        <p className="mb-4 question">
        Mennyibe kerül a felszerelés és hol lehet megvenni?
        </p>
        <p className="mb-4 answer">
        Általánosan nagyjából 50-60 ezer forintért lehet beszerezni a vállvédőt és a sisakot a használt piacon.
        A boltból vásárolt felszerelések ennél drágábbak, viszont a használtak is teljesen jól funkcionálnak
        ahhoz, hogy belépjen valaki a sportba.
        Emellett a csapatnak vannak vállvédői és sisakjai, hogy úgy is ki tudd próbálni a sportot, hogy ne
        kelljen ilyen jelentős összeget beruházni már a legelején.
        </p>
        <p className="mb-4 question">
        Milyen gyakoriak és súlyosak a sérülések?
        </p>
        <p className="mb-4 answer">
        Az amerikai foci egy kontakt sportág, így természetesen sérülések előfordulnak. Kellő felkészültséggel
        és megfelelő védőfelszerelésben léphet csak pályára mindenki, ebben nem ismerünk
        kompromisszumot. Az edzéseken figyelünk, hogy az alapokat elsajátíthasd, mielőtt komolyabb
        ütközéseket kapnál, a meccseken pedig az lép pályára, akinek a felkészültsége már megfelelő szinten
        van.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
